import React from "react";
import PG_C_MAIN_QR_CODE_CONTENT from "./index.content";
import SimpleScreenshot from "../../../common/UI/SimpleScreenshot";
import "./index.scss";

const QRCode = () => {
  const { screenshot, fullText } = PG_C_MAIN_QR_CODE_CONTENT;
  return (
    <section className="PgCMain-QRCode">
      <div className="container">
        <div className="PgCMain-QRCode__wrap">
          <div className="PgCMain-QRCode__content">
            <div className="PgCMain-QRCode__wrapText">
              {fullText.map((text, i) => (
                <p key={i}>{text}</p>
              ))}
            </div>
            <SimpleScreenshot
              classes="PgCMain-QRCode__img"
              screenshot={screenshot}
              from={768}
              to={767}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default QRCode;
