import JpgSecOneOneOne from "../../../../assets/images/raster/pages/cn/main/SectionOne/cn-s1-01_01.jpg"
import JpgSecOneOneOne2x from "../../../../assets/images/raster/pages/cn/main/SectionOne/cn-s1-01_01@15x.jpg"
import JpgSecOneOneTwo from "../../../../assets/images/raster/pages/cn/main/SectionOne/cn-s1-01_02.jpg"
import JpgSecOneOneTwo2x from "../../../../assets/images/raster/pages/cn/main/SectionOne/cn-s1-01_02@15x.jpg"
import JpgSecOneOneThree from "../../../../assets/images/raster/pages/cn/main/SectionOne/cn-s1-01_03.jpg"
import JpgSecOneOneThree2x from "../../../../assets/images/raster/pages/cn/main/SectionOne/cn-s1-01_03@15x.jpg"
import JpgSecOneOneFour from "../../../../assets/images/raster/pages/cn/main/SectionOne/cn-s1-01_04.jpg"
import JpgSecOneOneFour2x from "../../../../assets/images/raster/pages/cn/main/SectionOne/cn-s1-01_04@15x.jpg"
import JpgSecOneOneFive from "../../../../assets/images/raster/pages/cn/main/SectionOne/cn-s1-01_05.jpg"
import JpgSecOneOneFive2x from "../../../../assets/images/raster/pages/cn/main/SectionOne/cn-s1-01_05@15x.jpg"

import JpgSecOneTwoOne from "../../../../assets/images/raster/pages/cn/main/SectionOne/cn-s1-02_01.jpg"
import JpgSecOneTwoOne2x from "../../../../assets/images/raster/pages/cn/main/SectionOne/cn-s1-02_01@15x.jpg"
import JpgSecOneTwoTwo from "../../../../assets/images/raster/pages/cn/main/SectionOne/cn-s1-02_02.jpg"
import JpgSecOneTwoTwo2x from "../../../../assets/images/raster/pages/cn/main/SectionOne/cn-s1-02_02@15x.jpg"
import JpgSecOneTwoThree from "../../../../assets/images/raster/pages/cn/main/SectionOne/cn-s1-02_03.jpg"
import JpgSecOneTwoThree2x from "../../../../assets/images/raster/pages/cn/main/SectionOne/cn-s1-02_03@15x.jpg"
import JpgSecOneTwoFour from "../../../../assets/images/raster/pages/cn/main/SectionOne/cn-s1-02_04.jpg"
import JpgSecOneTwoFour2x from "../../../../assets/images/raster/pages/cn/main/SectionOne/cn-s1-02_04@15x.jpg"
import JpgSecOneTwoFive from "../../../../assets/images/raster/pages/cn/main/SectionOne/cn-s1-02_05.jpg"
import JpgSecOneTwoFive2x from "../../../../assets/images/raster/pages/cn/main/SectionOne/cn-s1-02_05@15x.jpg"

import JpgSecOneThreeOne from "../../../../assets/images/raster/pages/cn/main/SectionOne/cn-s1-03_01.jpg"
import JpgSecOneThreeOne2x from "../../../../assets/images/raster/pages/cn/main/SectionOne/cn-s1-03_01@15x.jpg"
import JpgSecOneThreeTwo from "../../../../assets/images/raster/pages/cn/main/SectionOne/cn-s1-03_02.jpg"
import JpgSecOneThreeTwo2x from "../../../../assets/images/raster/pages/cn/main/SectionOne/cn-s1-03_02@15x.jpg"
import JpgSecOneThreeThree from "../../../../assets/images/raster/pages/cn/main/SectionOne/cn-s1-03_03.jpg"
import JpgSecOneThreeThree2x from "../../../../assets/images/raster/pages/cn/main/SectionOne/cn-s1-03_03@15x.jpg"
import JpgSecOneThreeFour from "../../../../assets/images/raster/pages/cn/main/SectionOne/cn-s1-03_04.jpg"
import JpgSecOneThreeFour2x from "../../../../assets/images/raster/pages/cn/main/SectionOne/cn-s1-03_04@15x.jpg"
import JpgSecOneThreeFive from "../../../../assets/images/raster/pages/cn/main/SectionOne/cn-s1-03_05.jpg"
import JpgSecOneThreeFive2x from "../../../../assets/images/raster/pages/cn/main/SectionOne/cn-s1-03_05@15x.jpg"

import JpgSecOneFourOne from "../../../../assets/images/raster/pages/cn/main/SectionOne/cn-s1-04_01.jpg"
import JpgSecOneFourOne2x from "../../../../assets/images/raster/pages/cn/main/SectionOne/cn-s1-04_01@15x.jpg"
import JpgSecOneFourTwo from "../../../../assets/images/raster/pages/cn/main/SectionOne/cn-s1-04_02.jpg"
import JpgSecOneFourTwo2x from "../../../../assets/images/raster/pages/cn/main/SectionOne/cn-s1-04_02@15x.jpg"
import JpgSecOneFourThree from "../../../../assets/images/raster/pages/cn/main/SectionOne/cn-s1-04_03.jpg"
import JpgSecOneFourThree2x from "../../../../assets/images/raster/pages/cn/main/SectionOne/cn-s1-04_03@15x.jpg"
import JpgSecOneFourFour from "../../../../assets/images/raster/pages/cn/main/SectionOne/cn-s1-04_04.jpg"
import JpgSecOneFourFour2x from "../../../../assets/images/raster/pages/cn/main/SectionOne/cn-s1-04_04@15x.jpg"
import JpgSecOneFourFive from "../../../../assets/images/raster/pages/cn/main/SectionOne/cn-s1-04_05.jpg"
import JpgSecOneFourFive2x from "../../../../assets/images/raster/pages/cn/main/SectionOne/cn-s1-04_05@15x.jpg"

import JpgSecOneFiveOne from "../../../../assets/images/raster/pages/cn/main/SectionOne/cn-s1-05_01.jpg"
import JpgSecOneFiveOne2x from "../../../../assets/images/raster/pages/cn/main/SectionOne/cn-s1-05_01@15x.jpg"
import JpgSecOneFiveTwo from "../../../../assets/images/raster/pages/cn/main/SectionOne/cn-s1-05_02.jpg"
import JpgSecOneFiveTwo2x from "../../../../assets/images/raster/pages/cn/main/SectionOne/cn-s1-05_02@15x.jpg"
import JpgSecOneFiveThree from "../../../../assets/images/raster/pages/cn/main/SectionOne/cn-s1-05_03.jpg"
import JpgSecOneFiveThree2x from "../../../../assets/images/raster/pages/cn/main/SectionOne/cn-s1-05_03@15x.jpg"
import JpgSecOneFiveFour from "../../../../assets/images/raster/pages/cn/main/SectionOne/cn-s1-05_04.jpg"
import JpgSecOneFiveFour2x from "../../../../assets/images/raster/pages/cn/main/SectionOne/cn-s1-05_04@15x.jpg"
import JpgSecOneFiveFive from "../../../../assets/images/raster/pages/cn/main/SectionOne/cn-s1-05_05.jpg"
import JpgSecOneFiveFive2x from "../../../../assets/images/raster/pages/cn/main/SectionOne/cn-s1-05_05@15x.jpg"

import JpgSecOneSixOne from "../../../../assets/images/raster/pages/cn/main/SectionOne/cn-s1-06_01.jpg"
import JpgSecOneSixOne2x from "../../../../assets/images/raster/pages/cn/main/SectionOne/cn-s1-06_01@15x.jpg"
import JpgSecOneSixTwo from "../../../../assets/images/raster/pages/cn/main/SectionOne/cn-s1-06_02.jpg"
import JpgSecOneSixTwo2x from "../../../../assets/images/raster/pages/cn/main/SectionOne/cn-s1-06_02@15x.jpg"
import JpgSecOneSixThree from "../../../../assets/images/raster/pages/cn/main/SectionOne/cn-s1-06_03.jpg"
import JpgSecOneSixThree2x from "../../../../assets/images/raster/pages/cn/main/SectionOne/cn-s1-06_03@15x.jpg"
import JpgSecOneSixFour from "../../../../assets/images/raster/pages/cn/main/SectionOne/cn-s1-06_04.jpg"
import JpgSecOneSixFour2x from "../../../../assets/images/raster/pages/cn/main/SectionOne/cn-s1-06_04@15x.jpg"
import JpgSecOneSixFive from "../../../../assets/images/raster/pages/cn/main/SectionOne/cn-s1-06_05.jpg"
import JpgSecOneSixFive2x from "../../../../assets/images/raster/pages/cn/main/SectionOne/cn-s1-06_05@15x.jpg"

const SETTING_CONTENT = {
  img: [
    [
      JpgSecOneOneOne,
      JpgSecOneOneOne2x,
      JpgSecOneOneTwo,
      JpgSecOneOneTwo2x,
      JpgSecOneOneThree,
      JpgSecOneOneThree2x,
      JpgSecOneOneFour,
      JpgSecOneOneFour2x,
      JpgSecOneOneFive,
      JpgSecOneOneFive2x,
    ],
    [
      JpgSecOneTwoOne,
      JpgSecOneTwoOne2x,
      JpgSecOneTwoTwo,
      JpgSecOneTwoTwo2x,
      JpgSecOneTwoThree,
      JpgSecOneTwoThree2x,
      JpgSecOneTwoFour,
      JpgSecOneTwoFour2x,
      JpgSecOneTwoFive,
      JpgSecOneTwoFive2x,
    ],
    [
      JpgSecOneThreeOne,
      JpgSecOneThreeOne2x,
      JpgSecOneThreeTwo,
      JpgSecOneThreeTwo2x,
      JpgSecOneThreeThree,
      JpgSecOneThreeThree2x,
      JpgSecOneThreeFour,
      JpgSecOneThreeFour2x,
      JpgSecOneThreeFive,
      JpgSecOneThreeFive2x,
    ],
    [
      JpgSecOneFourOne,
      JpgSecOneFourOne2x,
      JpgSecOneFourTwo,
      JpgSecOneFourTwo2x,
      JpgSecOneFourThree,
      JpgSecOneFourThree2x,
      JpgSecOneFourFour,
      JpgSecOneFourFour2x,
      JpgSecOneFourFive,
      JpgSecOneFourFive2x,
    ],
    [
      JpgSecOneFiveOne,
      JpgSecOneFiveOne2x,
      JpgSecOneFiveTwo,
      JpgSecOneFiveTwo2x,
      JpgSecOneFiveThree,
      JpgSecOneFiveThree2x,
      JpgSecOneFiveFour,
      JpgSecOneFiveFour2x,
      JpgSecOneFiveFive,
      JpgSecOneFiveFive2x,
    ],
    [
      JpgSecOneSixOne,
      JpgSecOneSixOne2x,
      JpgSecOneSixTwo,
      JpgSecOneSixTwo2x,
      JpgSecOneSixThree,
      JpgSecOneSixThree2x,
      JpgSecOneSixFour,
      JpgSecOneSixFour2x,
      JpgSecOneSixFive,
      JpgSecOneSixFive2x,
    ],
  ],

  paragraph: [
    [
      "利用亚马逊产品数据库寻找高需求低竞争的产品",
      "找到一款月销爆款的高利润产品",
    ],
    [
      "使用简单的图标来识别各种细分产品类别的过去与未来的增长趋势",
      "在竞争激烈之前抓住市场机会",
    ],
    [
      "利用我们的总体市场评分来指导您的产品决策。简单解释一下某个类别的需求、竞争和盈利能力，看看某个特定的细分市场是否值得进入",
    ],
    [
      "使用产品历史图形来跟踪任何产品，包括您的竞争对手列表",
      "从销售、价格、排名数据库了解竞争对手的策略是否有效",
    ],

    ["检查预算的每个细节从而准确估算利润，避免产生错误的财务报告"],
    [
      "比竞争对手领先一步，调查目前寻找细分市场的卖家数量，制定策略以避免市场饱和",
    ],
  ],
  titles: [
    `寻找高利润的热销产品`,
    `确定增长趋势`,
    `验证细分市场创意`,
    `跟踪其他卖家`,
    `计算您的利润与费用`,
    `预估未来的细分市场竞争情况`,
  ],
  numbers: ["一.", "二.", "三.", "四.", "五.", "六."],
}

export default SETTING_CONTENT
