import React from "react";
import PropTypes from "prop-types";
import List from "./List";
import Image from "./Image";
import "./index.scss";

const Setting = ({ getImage, setImage }) => {
  return (
    <section className="PgCMain-S">
      <div className="PgCMain-S__container">
        <div className="PgCMain-S__wrapperTitle">
          <h2 className="PgCMain-S__title">AMZ侦探 PRO Chrome 和 Edge 插件</h2>
          <p>在Chrome和Edge浏览器上一键获取竞品信息</p>
        </div>
        <div className="PgCMain-S__secOne">
          <div className="PgCMain-S__wrapList">
            <List getImage={getImage} setImage={setImage} />
          </div>
          <div className="PgCMain-S__wrapImg">
            <Image pictureList={getImage} />
          </div>
        </div>
      </div>
    </section>
  );
};
Setting.propTypes = {
  getImage: PropTypes.array,
  setImage: PropTypes.func,
};
export default Setting;
