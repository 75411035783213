import React from "react";
import PG_C_MAIN_COUNTRIES_CONTENT from "./index.content";
import CountriesIcons from "./CountriesIcons";
import "./index.scss";

const Countries = ({ isMobile, extensionRef, id }) => {
  const { img } = PG_C_MAIN_COUNTRIES_CONTENT;

  return (
    <section className="PgCMain-Countries">
      <div className="PgCMain-Countries__container">
        <p>AMZ侦探 PRO插件支持10个站点</p>
        {isMobile ? (
          <>
            <ul
              ref={extensionRef}
              id={id}
              className="PgCMain-Countries__container-OneRow"
            >
              <CountriesIcons start={0} end={5} img={img} />
            </ul>
            <ul className="PgCMain-Countries__container-TwoRow">
              <CountriesIcons start={5} end={img.length} img={img} />
            </ul>
          </>
        ) : (
          <ul
            ref={extensionRef}
            id={id}
            className="PgCMain-Countries__container-OneRow"
          >
            <CountriesIcons start={0} end={img.length} img={img} />
          </ul>
        )}
      </div>
    </section>
  );
};

export default Countries;
