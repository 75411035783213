import React from "react";
import PropTypes from "prop-types";
import "../ControlsEmailCollect/index.scss";

const GetTitle = (props) => {
  const { isNodeTitle, title, noCreditCardRequired, isMobile } = props;
  return isMobile && !title ? null : isNodeTitle ? (
    <div className="emailCollect__wrapTitle">
      {title ? <p className="emailCollect__title">{title}</p> : null}
      <div className="emailCollect__wrapInstruction">
        <div className="emailCollect__noCreditCardRequired">
          {noCreditCardRequired}
        </div>
      </div>
    </div>
  ) : (
    <p className="emailCollect__title">
      {title}
      <span>{noCreditCardRequired}</span>
    </p>
  );
};
GetTitle.propTypes = {
  title: PropTypes.string,
  isNodeTitle: PropTypes.bool,
  noCreditCardRequired: PropTypes.string,
  isMobile: PropTypes.bool,
};

export default GetTitle;
