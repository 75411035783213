import React from "react";
import Paragraph from "./Paragraph";
import SETTING_CONTENT from "../index.content";

const Item = ({ index, getImage }) => {
  const { img, titles, numbers, paragraph } = SETTING_CONTENT;

  const parameter = getImage !== img[index] ? "closed" : "open";

  return (
    <div className={`PgCMain-S__${parameter}`}>
      <div className={`PgCMain-S__${parameter}-text`}>
        <span>{numbers[index]}</span>
        {titles[index]}
      </div>
      {parameter === "open" && <Paragraph paragraph={paragraph[index]} />}
      <div className={`PgCMain-S__${parameter}-line`} />
    </div>
  );
};
export default Item;
