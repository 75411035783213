import WEBP from "../../../../assets/images/raster/pages/cn/main/QRCode/cn-QRCode.webp"
import WEBP2x from "../../../../assets/images/raster/pages/cn/main/QRCode/cn-QRCode@2x.webp"
import JPEG from "../../../../assets/images/raster/pages/cn/main/QRCode/cn-QRCode.jpg"
import JPEG2x from "../../../../assets/images/raster/pages/cn/main/QRCode/cn-QRCode@2x.jpg"
import WEBPMob from "../../../../assets/images/raster/pages/cn/main/QRCode/cn-QRCode@mob.webp"
import WEBPMob2x from "../../../../assets/images/raster/pages/cn/main/QRCode/cn-QRCode@mob2x.webp"
import JPEGMob from "../../../../assets/images/raster/pages/cn/main/QRCode/cn-QRCode@mob.jpg"
import JPEGMob2x from "../../../../assets/images/raster/pages/cn/main/QRCode/cn-QRCode@mob2x.jpg"

const PG_C_MAIN_QR_CODE_CONTENT = {
  fullText: [
    "扫码订阅我们的微信公众号，",
    "给我们发送“奖品”，",
    "为您的亚马逊业务获取有用的技巧！",
  ],
  screenshot: {
    WEBP,
    WEBP2x,
    JPEG,
    JPEG2x,
    WEBPMob,
    WEBPMob2x,
    JPEGMob,
    JPEGMob2x,
  },
}
export default PG_C_MAIN_QR_CODE_CONTENT
