import React from "react";
import "./index.scss";
import { PAGES } from "../../../../helpers/constants";
import CnEmailCollectContainer from "../../../../containers/common/forms/CnEmailCollectContainer";

const SectionEmailCollect = (props) => {
  const {
    isSecondStateCollectEmail,
    setIsSecondStateCollectEmail,
    isMobile,
    isLoading,
    redirectUrl,
    extFunc,
    extAction,
    redirect,
    buttonTextSecondState,
    installViewCustomClass,
    isCnUser,
    handleClick,
  } = props;
  return (
    <section className="PgCMain-SectionEmailCollect">
      <div
        className={`PgCMain-SectionEmailCollect__wrap ${
          isSecondStateCollectEmail
            ? "PgCMain-SectionEmailCollect__secondState"
            : ""
        }`}
      >
        <CnEmailCollectContainer
          pageFormState={isLoading ? false : isSecondStateCollectEmail}
          updatePageFormState={setIsSecondStateCollectEmail}
          page={PAGES.CN}
          buttonTextSecondState={buttonTextSecondState}
          noCreditCardRequired="无需信用卡"
          buttonTextFirstState="免费试用AMZ侦探!"
          customClass="cn-sectionEmailCollect"
          isNodeTitle
          title="输入您的电子邮件地址以开始免费试用！"
          isMobile={isMobile}
          installViewCustomClass={installViewCustomClass}
          information="通过输入您的电子邮件地址，您同意接收来自AMZ侦探的营销电子邮件。"
          redirectUrl={redirectUrl}
          redirect={redirect}
          extFunc={extFunc}
          extAction={extAction}
          isCnUser={isCnUser}
          handleClick={handleClick}
        />
      </div>
    </section>
  );
};

export default SectionEmailCollect;
