import React from "react";

const Paragraph = ({ paragraph }) => (
  <div className="PgCMain-S__paragraph">
    {paragraph.map((text, i) => (
      <div key={i}>{text}</div>
    ))}
  </div>
);

export default Paragraph;
