import React from "react";
import PropTypes from "prop-types";
import { getTrueClasses } from "../../../../helpers";
import SectionEmailCollect from "../SectionEmailCollect";
import "./index.scss";

const SectionFive = ({
  handleClick,
  isSecondStateCollectEmail,
  setIsSecondStateCollectEmail,
  isMobile,
  isLoading,
  isCnUser,
  isOpacity,
}) => {
  return (
    <section
      className={getTrueClasses(
        "PgCMain-SectionFive",
        isOpacity && "PgCMain-SectionFive__opacity"
      )}
    >
      <SectionEmailCollect
        isSecondStateCollectEmail={isSecondStateCollectEmail}
        setIsSecondStateCollectEmail={setIsSecondStateCollectEmail}
        isMobile={isMobile}
        isLoading={isLoading}
        isCnUser={isCnUser}
        redirect={!isCnUser}
        handleClick={handleClick}
        buttonTextSecondState="安装 AMZ侦探 PRO Extension"
        installViewCustomClass="PgCMain-SectionFive__redirectedButton"
      />
    </section>
  );
};

SectionFive.propTypes = {
  handleClick: PropTypes.func,
  setIsSecondStateCollectEmail: PropTypes.func,
  isSecondStateCollectEmail: PropTypes.bool,
  isCnUser: PropTypes.bool,
  isOpacity: PropTypes.bool,
};
export default SectionFive;
