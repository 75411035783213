import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { graphql, useStaticQuery } from "gatsby";
import Intro from "../../components/cn/main/Intro";

const IntroContainer = (props) => {
  const {
    isPaid,
    setIsPaid,
    isSecondStateCollectEmail,
    setIsSecondStateCollectEmail,
    isLoading,
    isOpacity,
    setIsOpacity,
    redirectUrl,
  } = props;

  const [getDisplayError, setDisplayError] = useState(false);
  const [isShowVideo, setIsShowVideo] = useState(false);

  useEffect(() => {
    const timer = isShowVideo
      ? setTimeout(() => {
          setDisplayError(true);
        }, 4000)
      : null;
    return () => clearTimeout(timer);
  }, [isShowVideo]);

  const data = useStaticQuery(graphql`
    query {
      allFile(filter: { name: { regex: "/cn-home/" }, ext: { eq: ".jpg" } }) {
        edges {
          node {
            id
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                placeholder: BLURRED
                formats: [AUTO, WEBP]
                blurredOptions: { toFormat: AUTO }
                jpgOptions: { progressive: false }
              )
            }
            name
          }
        }
      }
    }
  `);

  return (
    <Intro
      isPaid={isPaid}
      setIsPaid={setIsPaid}
      isSecondState={isSecondStateCollectEmail}
      setIsSecondState={setIsSecondStateCollectEmail}
      isLoading={isLoading}
      isOpacity={isOpacity}
      setIsOpacity={setIsOpacity}
      redirectUrl={redirectUrl}
      data={data}
      isShowVideo={isShowVideo}
      setIsShowVideo={setIsShowVideo}
      getDisplayError={getDisplayError}
    />
  );
};

IntroContainer.propTypes = {
  isSecondStateCollectEmail: PropTypes.bool,
  setIsSecondStateCollectEmail: PropTypes.func,
  isLoading: PropTypes.bool,
  setIsPaid: PropTypes.func,
  isPaid: PropTypes.bool,
};

export default IntroContainer;
