import React from "react";
import PropTypes from "prop-types";
import { PAGES } from "../../../../helpers/constants";
import INTRO_CONTENT from "./index.content";
import { getTrueClasses } from "../../../../helpers";
import IntroPoints from "./IntroPoints";
import LicenseEmailCollectContainer from "../../../../containers/common/forms/LicenseEmailCollectContainer";
import IntroOffer from "./IntroOffer";
import IntroScreenshot from "./IntroScreenshot";
import "./emailCollect.scss";
import "./index.scss";

const Intro = (props) => {
  const {
    isPaid,
    setIsPaid,
    isSecondState,
    setIsSecondState,
    isLoading,
    isOpacity,
    setIsOpacity,
    redirectUrl,
    data,
    isShowVideo,
    setIsShowVideo,
    getDisplayError,
  } = props;

  const status = (isLoading, isSecondState, data, isPaid) => {
    if (isLoading ? false : isSecondState) {
      if (isPaid) {
        return data.paid;
      } else {
        return data.trial;
      }
    } else {
      return data.unauthorized;
    }
  };
  return (
    <section className="PgCMain-I" id="home">
      <div className="PgCMain-I__container">
        <div
          className={getTrueClasses(
            "PgCMain-I__info",
            (isLoading ? false : isSecondState) && "PgCMain-I__info_secondState"
          )}
        >
          <h1 className="PgCMain-I__title">用于在亚马逊上运营的一整个工具箱</h1>
          <IntroPoints />
          <LicenseEmailCollectContainer
            pageFormState={isLoading ? false : isSecondState}
            updatePageFormState={setIsSecondState}
            page={PAGES.CN}
            buttonTextFirstState="免费试用AMZ侦探!"
            buttonTextSecondState={
              isPaid ? "免费试用AMZ侦探!" : "免费试用AMZ侦探!"
            }
            customClass={getTrueClasses(
              "PgCMain-I-emailCollect",
              isOpacity && "emailCollect__opacity"
            )}
            redirectUrl={redirectUrl}
            isOpacity={isOpacity}
            setIsOpacity={setIsOpacity}
            setIsPaid={setIsPaid}
            isCheckLicense
            language="cn"
          />
          <IntroOffer
            data={status(isLoading, isSecondState, INTRO_CONTENT, isPaid)}
            classes="PgCMain-I-Offer"
            isOpacity={isOpacity}
          />
        </div>
        <IntroScreenshot
          isShowVideo={isShowVideo}
          setIsShowVideo={setIsShowVideo}
          data={data}
          getDisplayError={getDisplayError}
        />
      </div>
    </section>
  );
};

Intro.propTypes = {
  isSecondStateCollectEmail: PropTypes.bool,
  setIsSecondStateCollectEmail: PropTypes.func,
  isLoading: PropTypes.bool,
  setIsPaid: PropTypes.func,
  isPaid: PropTypes.bool,
};

export default Intro;
