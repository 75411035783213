import React from "react";
import PropTypes from "prop-types";
import GetTitle from "./GetTitle";
import GetForm from "./GetForm";
import "../ControlsEmailCollect/index.scss";
import { PAGES } from "../../../../helpers/constants";
import CnRedirectedButtonContainers from "../../../../containers/common/forms/CnRedirectedButtonContainers";
import CnSectionFour from "../../../cn/main/SectionFour";
import "./index.scss";

const EmailCollectCn = (props) => {
  const {
    title,
    page,
    buttonTextFirstState,
    buttonTextSecondState,
    installViewCustomClass,
    customClass,
    placeholderInput,
    information,
    isNodeTitle,
    noCreditCardRequired,
    isMobile,
    btnMaxContent,
    redirectUrl,
    onEmailChange,
    isAuth,
    emailValue,
    isLoading,
    onSubmit,
    onError,
    onKey,
    handleClick,
    isCnUser,
  } = props;
  const classes = [
    "emailView",
    "emailCollect",
    `emailCollect-${PAGES[page].toLowerCase()}`,
    page === PAGES.PD ? "emailCollect-pd" : "emailCollect_center",
    customClass,
  ]
    .filter(Boolean)
    .join(" ");
  return !isAuth ? (
    <div className={classes}>
      <GetTitle
        isNodeTitle={isNodeTitle}
        title={title}
        noCreditCardRequired={noCreditCardRequired}
        isMobile={isMobile}
      />
      <GetForm
        placeholderInput={placeholderInput}
        isLoading={isLoading}
        buttonTextFirstState={buttonTextFirstState}
        information={information}
        btnMaxContent={btnMaxContent}
        isMobile={isMobile}
        emailValue={emailValue}
        onEmailChange={onEmailChange}
        onSubmit={onSubmit}
        onError={onError}
        onKey={onKey}
      />
    </div>
  ) : isCnUser ? (
    <CnSectionFour handleClick={handleClick} />
  ) : (
    <CnRedirectedButtonContainers
      href={redirectUrl}
      title={buttonTextSecondState}
      page={page}
      customClass={installViewCustomClass}
    />
  );
};

EmailCollectCn.propTypes = {
  title: PropTypes.string,
  page: PropTypes.string,
  buttonTextFirstState: PropTypes.string,
  buttonTextSecondState: PropTypes.string,
  installViewCustomClass: PropTypes.string,
  customClass: PropTypes.string,
  placeholderInput: PropTypes.string,
  information: PropTypes.string,
  isNodeTitle: PropTypes.bool,
  noCreditCardRequired: PropTypes.string,
  isMobile: PropTypes.bool,
  redirectUrl: PropTypes.string,
  onEmailChange: PropTypes.func,
  isAuth: PropTypes.bool,
  emailValue: PropTypes.string,
  isLoading: PropTypes.bool,
  onSubmit: PropTypes.func,
  onError: PropTypes.string,
  onKey: PropTypes.func,
};

export default EmailCollectCn;
