import React from "react";
import PropTypes from "prop-types";
import track from "../../../tracking/analytics";
import { BUTTON_BACKGROUND, PAGES } from "../../../helpers/constants";
import CnRedirectedButton from "../../../components/common/Forms/emailCollectCn/CnRedirectedButton";

const CnRedirectedButtonContainers = (props) => {
  const {
    title,
    href,
    background = BUTTON_BACKGROUND.GRADIENT,
    page,
    customClass,
  } = props;
  const classes = [
    "installView",
    "emailCollect",
    page ? `installView-${PAGES[page].toLowerCase()}` : "",
    customClass,
  ]
    .filter(Boolean)
    .join(" ");

  const handleClick = () => {
    track("CNIndex", href, "TryForFree", undefined, "LANDING");
  };

  return (
    <CnRedirectedButton
      classes={classes}
      background={background}
      handleClick={handleClick}
      href={href}
      title={title}
    />
  );
};

CnRedirectedButtonContainers.propTypes = {
  title: PropTypes.string,
  href: PropTypes.string,
  page: PropTypes.string,
  isIntro: PropTypes.bool,
  background: PropTypes.string,
  customClass: PropTypes.string,
};

export default CnRedirectedButtonContainers;
