import React from "react";
import Item from "./Item";
import SETTING_CONTENT from "../index.content";

const List = ({ getImage, setImage }) => {
  const { titles, img } = SETTING_CONTENT;

  const res = [];
  for (let i = 0; i < titles.length; i++) {
    res.push(
      <li key={i} onClick={() => setImage(img[i])}>
        <Item index={i} getImage={getImage} />
      </li>
    );
  }
  return <ul id="check">{res}</ul>;
};
export default List;
