import React from "react";
import PropTypes from "prop-types";

const Image = ({ pictureList }) => {
  return (
    <picture>
      <source
        media="(max-width: 767px)"
        srcSet={`${pictureList[8]} 1x, ${pictureList[9]} 2x`}
      />
      <source
        media="(min-width: 768px) and (max-width: 991px)"
        srcSet={`${pictureList[6]} 1x, ${pictureList[7]} 2x`}
      />
      <source
        media="(min-width: 992px) and (max-width: 1199px)"
        srcSet={`${pictureList[4]} 1x, ${pictureList[5]} 2x`}
      />
      <source
        media="(min-width: 1200px) and (max-width: 1365px)"
        srcSet={`${pictureList[2]} 1x, ${pictureList[3]} 2x`}
      />
      <source
        media="(min-width: 1366px)"
        srcSet={`${pictureList[0]} 1x, ${pictureList[1]} 2x`}
      />
      <img src={`${pictureList[0]}`} alt="img" />
    </picture>
  );
};
Image.propTypes = {
  pictureList: PropTypes.array,
};
export default Image;
