import React from "react";
import { getTrueClasses } from "../../../../../helpers";
import SvgCheckbox from "../../../../../assets/images/vector/components/common/mainPage-home_01.svg";
import "./index.scss";

const IntroOffer = ({ data, isOpacity }) => {
  const { style, title, list } = data;

  return (
    <div
      className={getTrueClasses(
        "PgCMain-I-Offer",
        style && `${style[0]}`,
        isOpacity && "PgCMain-I-Offer-opacity"
      )}
    >
      <p
        className={getTrueClasses(
          "PgCMain-I-Offer-title",
          style && `${style[1]}`
        )}
      >
        {title}
      </p>
      <ul
        className={getTrueClasses(
          "PgCMain-I-Offer-list",
          style && `${style[2]}`
        )}
      >
        {list.map((text, i) => (
          <li key={i}>
            <img className="PgCMain-I-Offer-img" src={SvgCheckbox} alt="-" />
            {text}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default IntroOffer;
